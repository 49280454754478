.SignOn
{
    padding: 2rem;
    text-align: center;
    height: calc(100% - 4rem);
}
.SignOnCode
{
    max-width: 15rem;
    margin:auto;
    margin-bottom: 1rem;
}
.code {
    background: transparent;
    border: none;
    color: #383838;
    /* border-bottom: 1px solid white; */
    border-radius: 0;
    text-align: center;
    font-size: 26px;
    line-height: 26px;
    padding: 0;
    height: auto;
    margin: auto;
}
.keypadContainer
{
    max-width: 15rem;
    margin:auto;
}
.clickedbtn{
    background-color: #29abe2;
    color: white;
}
.key
{
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    margin: 0rem 0.5rem 0.5rem .5rem;
    font-size: 1.25rem;
    padding: 1.25rem;
    line-height: 1;
    color: #383838;
    border-radius: 100%;
    border: 1px solid #383838;
}
.key:hover
{
    background:#e0e0e0;
}

.keyRow{}

/*.keyRow:first-child .key:first-child
{
    border-top-left-radius: 8px;
}
.keyRow:first-child .key:last-child
{
    border-top-right-radius: 8px;
}
.keyRow:last-child .key:first-child
{
    border-bottom-left-radius: 8px;
}
.keyRow:last-child .key:last-child
{
    border-bottom-right-radius: 8px;
}

.keyRow .key
{
    border-bottom:.1rem solid #2c3245;
}
.keyRow .key:first-child{
    border-right:.1rem solid #2c3245;
}
.keyRow .key:last-child{
    border-left:.1rem solid #2c3245;
}*/